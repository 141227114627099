<template>  
  <v-container>
    <v-row class="pa-4" justify="space-between">
    <v-col cols="12">  
      <v-textarea 
        v-model="description"
        outlined
        label="퀀트전략"
        auto-grow
        hint="퀀트전략을 입력 및 수정할 수 있습니다."
        persistent-hint
      >
      </v-textarea>
    </v-col>
    </v-row>

    <v-subheader align="start">기본필터의 값을 수정할 수 있습니다.</v-subheader>
    <v-row class="pa-4" justify="space-between">
      <!-- 시장 -->
      <v-col cols="12" sm="4">   
        <v-subheader class="pl-0">시장</v-subheader>         
        <v-radio-group 
          v-model="market"     
          row
        >   
          <v-radio label="ANY" value="ANY"></v-radio>
          <v-radio label="KOSPI" value="KOSPI"></v-radio>
          <v-radio label="KOSDAQ" value="KOSDAQ"></v-radio>     
          <v-radio label="KOSDAQ GLOBAL" value="KOSDAQ GLOBAL"></v-radio>        
          <v-radio label="KONEX" value="KONEX"></v-radio>
        </v-radio-group> 
      </v-col>

      <!-- 시총 -->
      <v-col cols="12" sm="4">  
          <v-subheader class="pl-0">시총</v-subheader>
          <v-slider
            v-model="market_cap"            
            min="1" 
            max="100" 
            :thumb-size="32" 
            thumb-label="always"
          >
          </v-slider>
          하위 {{ market_cap }} % 이하
      </v-col>
      
      <!-- 업종 -->
      <v-col cols="12" sm="4">
          <v-subheader class="pl-0">업종</v-subheader>
          <v-combobox
            v-model="sector"             
            :items="sector_options" 
            multiple clearable small-chips
          >
          </v-combobox>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';

export default {  
  name: 'FilterCard',
  data () {
    return {
      sector_options: ['전체', '비금속', '운송장비·부품', '숙박·음식', '섬유·의류', '의료정밀', '통신장비', '기계·장비', '건설업', '음식료·담배', '비금속광물', '통신업',
                      '컴퓨터서비스', '기타금융','서비스업','일반전기전자','전기전자','반도체','전기가스업','유통','정보기기','운수창고업','화학','음식료품','기타제조업',
                      '방송서비스','보험','유통업','금속','건설','증권','철강금속','금융','오락·문화','운송','기계','기타서비스','기타제조','운수장비','전기·가스·수도',
                      '인터넷','종이·목재','디지털컨텐츠','제약','광업','통신서비스','IT부품','소프트웨어','농업, 임업 및 어업','은행','출판·매체복제','의약품','섬유의복',
                      '종이목재','의료·정밀기기',
                      ],       
    }
  },

  computed: {
    ...mapState({
      descriptionFromStore: 'description',
      marketFromStore: 'market',
      marketcapFromStore: 'market_cap',
      sectorFromStore: 'sector',
    }),
    description: {
      get(){
        return this.descriptionFromStore
      },
      set(newDescription){
        this.$store.state.description = newDescription
      } 
    },
    market: {
      get(){
        return this.marketFromStore
      },
      set(newMarket){
        this.$store.state.market = newMarket
      } 
    },
    market_cap: {
      get(){
        return this.marketcapFromStore
      },
      set(newMarketCap){
        this.$store.state.market_cap = newMarketCap
      } 
    },
    sector: {
      get(){
        return this.sectorFromStore
      },
      set(newSector){
        this.$store.state.sector = newSector
      } 
    },
  },
   
}
</script>
