<template>
  <div>
    <v-card
        flat
    >
      <v-card-subtitle align="end">            
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <v-icon class="pr-4" color="primary" dark v-on="on"> mdi-information </v-icon>
          </template>
          <span>전략보기</span>
        </v-tooltip> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <v-icon class="pr-4" color="primary" dark v-on="on"> mdi-magnify-expand </v-icon>
          </template>
          <span>종목찾기</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <v-icon class="pr-4" color="primary" dark v-on="on"> mdi-finance </v-icon>
          </template>
          <span>백테스트</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <v-icon class="pr-16" color="primary" dark v-on="on"> mdi-delete </v-icon>
          </template>
          <span>전략삭제</span>
        </v-tooltip>    
      </v-card-subtitle>
    </v-card>
    <v-data-table
      :headers="quantHeaders"
      :items="quantList"
      :items-per-page="1000"
      item-key="quant_id"
      class="elevation-1"
      :hide-default-footer="true"   
    > 
    <template v-slot:top>  
      <v-dialog 
        v-model="dialogDelete"
        width="auto" 
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-card-title class="text-h6">{{title}} 전략을 삭제하시겠습니까?</v-card-title>
          <v-divider></v-divider>
          <v-card-actions class="pa-6">
              <v-spacer></v-spacer>
              <v-btn depressed outlined @click="closeDelete">취소</v-btn>
              <v-btn color="primary" @click="deleteItemConfirm">확인</v-btn>
              <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>      
        <v-dialog 
          v-model="dialogRule" 
          width="auto" 
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card>
              <!-- <v-card-title class="text-h5" >{{ title }}</v-card-title> -->
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-container>
                  <v-row><v-col>
                      <v-text-field
                          v-model="title"
                          label="제목"
                          class="d-flex justify-start pl-9"
                          hint="제목을 수정할 수 있습니다."
                      ></v-text-field>
                  </v-col></v-row>            
                  <v-row><v-col>  
                    <FilterCard />
                  </v-col></v-row>
                  <v-row><v-col>
                    <IndexTable />
                  </v-col></v-row>
                  </v-container>
                  <v-spacer></v-spacer>
              </v-card-actions>
              <v-divider></v-divider>
              <v-card-actions class="pa-6">
                  <v-spacer></v-spacer>                    
                  <v-btn depressed outlined @click="close">닫기</v-btn>
                  <v-btn color="primary" @click="update_rules()">저장</v-btn>
                  <v-btn color="primary" @click="search">종목검색</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog 
          v-model="dialogTest"
          width="auto" 
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card>
              <v-card-title class="text-h5">{{title}} 백테스트</v-card-title>
              <v-card-text>
              <v-container>                        
                  <v-row>
                  <v-col cols="12" sm="6" md="3" lg="3" class="d-flex justify-start">
                      <v-text-field
                      label="초기투자금(만원)" required                            
                      v-model="amount_selected"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" lg="3" class="d-flex justify-start">
                      <v-text-field
                      label="편입종목수" required                            
                      v-model="stock_selected"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" lg="3" class="d-flex justify-start">
                      <v-text-field
                      label="테스트기간(년)" required                            
                      v-model="time_selected"
                      hint="테스트 데이터는 2015년 4월부터 가능합니다."
                      persistent-hint
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" lg="3" class="d-flex justify-start">
                      <v-select
                      :items="tradeTerms"
                      :menu-props="{ top: true, offsetY: true }"
                      label="리밸런싱주기*" required
                      v-model="term_selected"
                      ></v-select>                        
                  </v-col>
                  </v-row>       
                  <v-row align="start" class="mx-0">
                      <small>
                          <v-list dense>
                          <v-subheader>*리밸런싱주기</v-subheader>
                          <v-list-item>월  : 매월말   </v-list-item> 
                          <v-list-item>분기: 3월말(4분기), 5월말(1분기), 8월말(2분기), 11월말 (3분기) </v-list-item>
                          <v-list-item>반기: 5월말(1분기), 11월말 (3분기) </v-list-item>
                          <v-list-item>연간: 3월말(4분기) </v-list-item>
                          <v-list-item>시즌제: 올해 매수싯점(월말) ~ 다음해 매도싯점(월말) </v-list-item>
                          </v-list>
                      </small>          
                  </v-row>                 
              </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="pa-6">
                  <v-spacer></v-spacer><v-spacer></v-spacer>
                  <v-btn depressed outlined @click="dialogTest = false">닫기</v-btn>
                  <v-btn color="primary" @click="backtest_start">백테스트 시작</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <template v-slot:item.actions="{ item }">        
        <!-- <v-icon small class="mr-4" @click="get_rules(item)"> mdi-information </v-icon> -->
        <v-icon small class="mr-4" @click="get_rules(item)"> mdi-magnify-expand </v-icon> 
        <v-icon small class="mr-4" @click="backtest(item)"> mdi-finance </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    </v-data-table>

    <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
    >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
    <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
    >
        Close
    </v-btn>
    </template>
    </v-snackbar>
</div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import IndexTable from '../components/IndexTable.vue';
  import FilterCard from '../components/FilterCard.vue';

  export default {
    props: ['quantList'],
    components: { IndexTable, FilterCard },
    data () {
      return {
        dialogRule: false,
        dialogDelete: false,
        dialogTest: false,
        title: null,
        editedIndex: -1,
        editedItem: {
            quant_id: 0,
            title: '',
            market: '',
            market_cap: 10000,
            sector: [],
        },

        defaultItem: {
            quant_id: 0,
            title: '',
            market: '',
            market_cap: 10000,
            sector: [],
        },

        quantHeaders : [
          { text: '제목', value: 'title' },
          { text: '시장', value: 'market' },
          { text: '시총', value: 'market_cap' },
          { text: '업종', value: 'sector' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        ruleTableShow: false,
        quantID: null,
        tradeTerms: ['월','분기','반기','년','시즌'],
        amount_selected: null,
        stock_selected: null,
        time_selected: null,
        term_selected: null,
        test_text: {},

        snackbar: false,
        snackText: null,
        snackColor: null,
        vertical: true,
    
      }
    },
    
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    computed: {
        ...mapState(['tab', 'user', 'description', 'market', 'market_cap', 'sector', 'filters', 'rules']) 
    },
    
    methods: {
        ...mapMutations(['setMarket', 'setFilter', 'setRule', 'setTest', 'setRoute', 'setTitle']),       
        

        search() {
          this.$router.push('/search');
        },

        get_rules(item) {   
          var vm = this; 
          this.quantID = item['quant_id']
          const user_id = this.tab == 'expert' ? 'expert' : this.user;
          const req_data = {'user': user_id};
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
          axios.post('/api/quant/rule/'+this.quantID+'/', req_data, {headers})
            .then(function(res) {
              vm.title = res.data['title'];
              vm.setTitle({'title': vm.title})
              vm.setMarket({'market': res.data['market'], 'market_cap': res.data['market_cap'], 'sector': res.data['sector'], 
                'description': res.data['description']});
              vm.setRule({'rules': res.data['rules']});
              vm.setFilter({'filters': res.data['filters']});   
              
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;           
            });
          this.dialogRule = true;        
        },
        
        backtest(item) {
            this.dialogTest = true;
            this.title = item['title'];
            this.test_text['quant_id'] = item['quant_id'];
            this.test_text['title'] = item['title'];                        
        },

        backtest_start() {
            if (this.amount_selected == null || this.stock_selected == null || 
            this.time_selected == null || this.term_selected == null) {
                this.snackbar = true;
                this.snackColor = 'red'
                this.snackText = '초기투자금, 편입종목수, 테스트기간, 리밸런싱주기를 입력해 주세요.'
            } else {
                this.setRoute({'route': 'my'});
                this.test_text['amount'] = this.amount_selected;
                this.test_text['stocks'] = this.stock_selected;
                this.test_text['time'] = this.time_selected;
                this.test_text['term'] = this.term_selected;
                this.setTest(this.test_text);
                this.$router.push('/backtest');
                this.dialogTest = false;
            }
        },

        update_rules() {
            this.dialogRule = false;
            var vm = this;
            
            const req_data = {'user': this.user, 'title': this.title, 'description': this.description, 
                'market': this.market, 'market_cap': this.market_cap, 'sector': this.sector, 'filters': this.filters, 'rules': this.rules}
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
            axios.post('/api/quant/rule/update/'+this.quantID+'/', req_data, {headers})
                .then(function(res) {
                  vm.snackbar = true;
                  vm.snackColor = 'success';
                  vm.snackText = '수정되었습니다.';           
                })
                .catch(function (err) {
                  vm.snackbar = true;
                  vm.snackColor = 'error';
                  vm.snackText = err;           
                });
        },

        deleteItem (item) {
            this.title = item['title'];
            this.editedIndex = this.quantList.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.quantID = item['quant_id'];
            this.dialogDelete = true;            
        },

        deleteItemConfirm () {
            var vm = this
            
            const req_data = {user: this.user}
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
            axios.post('/api/quant/rule/delete/'+vm.quantID+'/', req_data, {headers})
                .then(function(res) {
                    vm.quantList.splice(vm.editedIndex, 1)
                    vm.closeDelete();
                    vm.snackbar = true;
                    vm.snackColor = 'success';
                    vm.snackText = '삭제되었습니다.';   
                })
                .catch(function (err) {
                    vm.snackbar = true;
                    vm.snackColor = 'error';
                    vm.snackText = err;     
                });            
        },

        close () {
            this.dialogRule = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

    }
  }
</script>