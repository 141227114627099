<template>
<div>
  <v-card class="quant-rule" flat>
    <v-toolbar
      flat
      color="gray"
    >
      <v-toolbar-title class="text-h5 text--primary d-flex font-weight-bold"> 
        국내주식 퀀트 전략
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs
      v-model="tab"
    >
      <v-tab @click="get_quant_list('my')">
        <v-icon left>
          mdi-account
        </v-icon>
        나의 전략
      </v-tab>
      <v-tab @click="get_quant_list('expert')">
        <v-icon left>
          mdi-account-multiple-outline
        </v-icon>
        전문가 전략
      </v-tab>
      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item>
          <v-card flat>
            <MyRuleTable
              :quantList="myQuantList"
              />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <ExpertRuleTableV02
              :quantList="expertQuantList"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>

    <!-- 알림 메세지 -->
  <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>

</div>
</template>


<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import MyRuleTable from '../components/MyRuleTable.vue';
  import ExpertRuleTableV02 from '../components/ExpertRuleTableV02.vue';
  // import BacktestHelp from '../components/BacktestHelp.vue';
  
  export default {
    components: { MyRuleTable, ExpertRuleTableV02 },
    data () {
      return {
        tab: null,
        myQuantList: [],
        expertQuantList: [],
        expertRuleList: [],
        helpDialogShow: false,
        helpTitle: null,
        hs00: true,
        helpText: [
          {'id':0, 'text': '11111111111111111111'},
          {'id':1, 'text': '22222222222222222222'},
        ],
        snackbar: false,
        vertical: true,
        snackColor: null,
        snackText: null,
      }
    },
    
    computed: {
        ...mapState(['debug', 'isLogin', 'user']) 
    },

    created() {
      if (this.isLogin) this.get_quant_list('my');
      else this.$router.push('/');
    },

    methods: {
      ...mapMutations(['setTab']),

      get_quant_list(who) {
          this.setTab({'tab': who});        
          var vm = this;
          
          const req_data = {user: this.user}
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
          axios.post('/api/quant/quant/'+who+'/', req_data, {headers})
              .then(function(res) {
                  if (who == 'my') vm.myQuantList = res.data;
                  else if (who == 'expert') vm.expertQuantList = res.data;                    
              })
              .catch(function (err) {  
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;            
              });
      },
      showHelp() {
        this.helpTitle = '백 테스트';
        this.helpDialogShow = true;
      }

    },
  }
</script>

<style>
.quant-rule{
   padding: 10px 10px;
   margin-top: 10px;
}
</style>
