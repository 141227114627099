<template>
<div>
    <!-- Index Filter Table -->
    <v-card-subtitle align="start">지표 필터</v-card-subtitle>
    <v-data-table
      :headers="filterHeaders"
      :items="filters"
      :items-per-page="10"
      :hide-default-footer=true
      class="elevation-1"  
      no-data-text="지표가 없습니다."     
    > </v-data-table>

    <br>
    <!-- Rule Table -->
    <v-card-subtitle align="start">검색 지표</v-card-subtitle>
    <v-data-table
      :headers="ruleHeaders"
      :items="rules"
      :items-per-page="10"
      :hide-default-footer=true
      mobile-breakpoint="0"
      class="elevation-1"  
      no-data-text="지표가 없습니다."     
    > </v-data-table>

</div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  name: "IndexTableViewOnly",
  data() {
    return {
      filterHeaders: [
        {
          text: '항목',
          align: 'start',
          sortable: false,
          value: 'category',
        },
        { text: '지표', value: 'text' },
        { text: '망소/망대', value: 'flag' },
        { text: '최소값', value: 'min' },
        { text: '최대값', value: 'max' },
      ],
      ruleHeaders: [
        {
          text: '항목',
          align: 'start',
          sortable: false,
          value: 'category',
        },
        { text: '지표', value: 'text' },
        { text: '망소/망대', value: 'flag' },
        { text: '비중(%)', value: 'ratio' },
      ],

      sumRatio: 100,
      snackbar: false,
      snackText: null,
      snackColor: null,
      vertical: true,
    }
  },

  computed: {
    ...mapState(['debug', 'filters', 'rules'])
  },

  methods: { 
    ...mapMutations(['setFilter', 'setRule']),

    saveFilter() {
      this.setFilter({'filters': this.filters});
    },

    saveRule() {
      this.sumRatio = this.rules.map(item => parseInt(item.ratio)).reduce((prev, curr) => prev + curr, 0);
      if (this.sumRatio !== 100) {
          this.snackbar = true
          this.snackColor = 'red lighten-1'
          this.snackText = '비중 합계는 100%로 설정하세요.'
      } else {
        this.setRule({'rules': this.rules});
      }
    },
  }

}
</script>
