<template>
  <div>
  <v-dialog 
    v-model="dialogRule" 
    width="auto" 
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
        <!-- <v-card-title class="text-h5" >{{ title }}</v-card-title> -->
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-container>  
              <v-row><v-col>
                <div v-if="isManager" class="pl-9">
                <v-text-field
                  v-model="title"
                  label="제목"
                  hint="제목을 수정할 수 있습니다."
                  persistent-hint
                ></v-text-field>
                </div>
                <div v-else class="d-flex justify-start text-h5">
                  {{title}}
                </div>
              </v-col></v-row>            
              <v-row><v-col>
                <div v-if="isManager">  
                  <FilterCard />
                </div>
                <div v-else>
                  <FilterCardViewOnly />
                </div>
              </v-col></v-row>
              <v-row><v-col>
                <div v-if="isManager">
                  <IndexTable />
                </div>
                <div v-else>
                  <IndexTableViewOnly />
                </div>
              </v-col></v-row>
            </v-container>
            <v-spacer></v-spacer>
        </v-card-actions>    
        <v-divider></v-divider>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn depressed outlined @click="dialogRule=false">닫기</v-btn>
          <v-btn color="primary" v-if="isManager" @click="update_rules()">저장</v-btn>
          <v-btn color="primary" @click="search()">종목검색</v-btn>
        </v-card-actions>    
    </v-card>
  </v-dialog>
        
  <v-card
    flat
  >
    <v-card-subtitle align="start">
      수익율은 총 투자금 1천만원, 투자종목 10개, 과거 6년간, 반기 리밸런싱으로 백테스한 결과입니다.
    </v-card-subtitle>
    <v-card-subtitle align="end">      
      <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <v-icon class="pr-16" color="primary" dark v-on="on"> mdi-information </v-icon>
          </template>
          <span>전략보기</span>
      </v-tooltip> -->
      <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <v-icon class="pr-4" color="primary" dark v-on="on"> mdi-magnify-expand </v-icon>
          </template>
          <span>종목찾기</span>
      </v-tooltip>
      <v-tooltip bottom>
          <template v-slot:activator="{ on }">
          <v-icon class="pr-4" color="primary" dark v-on="on"> mdi-finance </v-icon>
          </template>
          <span>백테스트</span>
      </v-tooltip>
    </v-card-subtitle>
  </v-card>

  <v-data-table
      :headers="quantHeaders"
      :items="quantList"
      :items-per-page="10000"
      item-key="quant_id"
      class="elevation-1"
      :hide-default-footer="true"   
  > 
  <template v-slot:item.actions="{ item }">    
    <!-- <v-icon small class="mr-4" @click="get_rules(item)"> mdi-information </v-icon> -->
    <v-icon small class="mr-4" @click="get_rules(item)"> mdi-magnify-expand </v-icon>   
    <v-icon small class="mr-4" @click="backtest(item)" > mdi-finance </v-icon>
  </template>
  </v-data-table>

  <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
    >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
    <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
    >
        Close
    </v-btn>
    </template>
    </v-snackbar>

</div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import FilterCard from '../components/FilterCard.vue';
  import FilterCardViewOnly from '../components/FilterCardViewOnly.vue';  
  import IndexTable from '../components/IndexTable.vue';
  import IndexTableViewOnly from '../components/IndexTableViewOnly.vue';

  export default {
    props: ['quantList'],
    components: { FilterCard, FilterCardViewOnly, IndexTable, IndexTableViewOnly },
    data () {
      return {
        title: null,
        dialogTest: false,
        dialogRule: false,
        isManager: false,

        quantHeaders : [
          { text: '제목', value: 'title' },
          { text: '1개월수익율', value: 'month1_yield', align: 'end'},
          { text: '3개월수익율', value: 'month3_yield', align: 'end'},
          { text: '6개월수익율', value: 'month6_yield', align: 'end'},
          { text: '1년수익율', value: 'month12_yield', align: 'end'},
          { text: '총누적수익율', value: 'cum_yield', align: 'end'},
          { text: 'MDD', value: 'mdd', align: 'end'},
          { text: '샤프지수', value: 'sharpe', align: 'end'},
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        quantID: null,
        tradeTerms: ['월','분기','반기','년','시즌'],
        amount_selected: null,
        stock_selected: null,
        time_selected: null,
        term_selected: null,
        test_text: {},

        snackbar: false,
        snackText: null,
        snackColor: null,
        vertical: true,
      }
    },

    computed: {
        ...mapState(['tab', 'user', 'description', 'market', 'market_cap', 'sector', 'filters', 'rules']) 
    },

    methods: {
        ...mapMutations(['setMarket', 'setRule', 'setFilter', 'setTest', 'setRoute']),

        search() {
          this.$router.push('/search');
        },

        backtest(item) {            
            this.title = item['title'];
            this.test_text['who'] = 'expert'; 
            this.test_text['quant_id'] = item['quant_id'];
            this.test_text['title'] = item['title'];
            this.test_text['amount'] = this.amount_selected;
            this.test_text['stocks'] = this.stock_selected;
            this.test_text['time'] = this.time_selected;
            this.test_text['term'] = this.term_selected;
            this.setTest(this.test_text);
            this.setRule({'rules': item['rules']});
            this.setRoute({'route': 'expert'});
            
            this.$router.push('/backtest');
        },

        get_rules(item) {   
          var vm = this; 
          this.quantID = item['quant_id']
          const user_id = this.tab == 'expert' ? 'expert' : this.user;
          const req_data = {'user': user_id};
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
          axios.post('/api/quant/rule/'+this.quantID+'/', req_data, {headers})
            .then(function(res) {
              vm.title = res.data['title'];
              vm.setMarket({'market': res.data['market'], 'market_cap': res.data['market_cap'], 'sector': res.data['sector'], 
                'description': res.data['description']});
              vm.setRule({'rules': res.data['rules']});
              vm.setFilter({'filters': res.data['filters']});   
              
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;           
            });
          if (this.user == 'koas') this.isManager = true;              
          this.dialogRule = true;        
        },

        update_rules() {
            var vm = this;
            
            const req_data = {'user': 'expert', 'title': this.title, 'description': this.description, 
              'market': this.market, 'market_cap': this.market_cap, 
              'sector': this.sector, 'filters': this.filters, 'rules': this.rules}
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
            axios.post('/api/quant/rule/update/'+this.quantID+'/', req_data, {headers})
              .then(function(res) {
                vm.snackbar = true;
                vm.snackColor = 'success';
                vm.snackText = '수정되었습니다.';           
              })
              .catch(function (err) {
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;           
              });              
            this.dialogRule = false;            
        },

    }
  }
</script>