<template>
<div>
    <div style="color:red;" v-if="debug">
      IndexTable
    </div>

    <v-subheader align="start">지표필터의 최소값, 최대값을 수정할 수 있습니다.</v-subheader>

    <!-- Index Filter Table -->
    <v-data-table
      :headers="filterHeaders"
      :items="filters"
      :items-per-page="100"
      :hide-default-footer=true
      class="elevation-1"  
      no-data-text="지표가 없습니다."     
    >      
      <template v-slot:item.min="props">
        <v-edit-dialog
        :return-value.sync="props.item.min"
        persistent
        large
        @save="saveFilter"
        cancel-text="취소"
        save-text="저장"
        >
        <div>{{ props.item.min }}</div>
        <template v-slot:input>
            <div class="mt-4 text-h6">
            최소값
            </div>
            <v-text-field
            v-model="props.item.min"
            label="입력"
            single-line
            ></v-text-field>
        </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.max="props">
        <v-edit-dialog
        :return-value.sync="props.item.max"
        large
        persistent
        @save="saveFilter"
        cancel-text="취소"
        save-text="저장"        
        >
        <div>{{ props.item.max }}</div>
        <template v-slot:input>
            <div class="mt-4 text-h6">
            최대값
            </div>
            <v-text-field                
            v-model="props.item.max"
            label="입력"
            single-line
            ></v-text-field>
        </template>
        </v-edit-dialog>
      </template>
    </v-data-table>

    <br>
    <!-- Rule Table -->
    <v-subheader align="start">지표의 비중을 수정할 수 있습니다. (합계: 100%)</v-subheader>
    <v-data-table
      :headers="ruleHeaders"
      :items="rules"
      :items-per-page="100"
      :hide-default-footer=true
      class="elevation-1"  
      no-data-text="지표가 없습니다."     
    >      
      <template v-slot:item.ratio="props">
        <v-edit-dialog
        :return-value.sync="props.item.ratio"
        large
        persistent
        @save="saveRule"
        cancel-text="취소"
        save-text="저장"        
        >
        <div>{{ props.item.ratio }}</div>
        <template v-slot:input>
            <div class="mt-4 text-h6">
            비중
            </div>
            <v-text-field
            v-model="props.item.ratio"
            label="입력"
            single-line
            ></v-text-field>
        </template>
        </v-edit-dialog>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :vertical="vertical"
      :color="snackColor"          
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

</div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  name: "IndexTable",
  data() {
    return {
      filterHeaders: [
        {
          text: '항목',
          align: 'start',
          sortable: false,
          value: 'category',
        },
        { text: '지표', value: 'text' },
        { text: '망소/망대', value: 'flag' },
        { text: '최소값', value: 'min' },
        { text: '최대값', value: 'max' },
      ],
      ruleHeaders: [
        {
          text: '항목',
          align: 'start',
          sortable: false,
          value: 'category',
        },
        { text: '지표', value: 'text' },
        { text: '망소/망대', value: 'flag' },
        { text: '비중(%)', value: 'ratio' },
      ],

      sumRatio: 100,
      snackbar: false,
      snackText: null,
      snackColor: null,
      vertical: true,
    }
  },

  computed: {
    ...mapState(['debug', 'filters', 'rules'])
  },

  methods: { 
    ...mapMutations(['setFilter', 'setRule']),

    saveFilter() {
      this.setFilter({'filters': this.filters});
    },

    saveRule() {
      this.sumRatio = this.rules.map(item => parseInt(item.ratio)).reduce((prev, curr) => prev + curr, 0);
      if (this.sumRatio !== 100) {
          this.snackbar = true
          this.snackColor = 'red lighten-1'
          this.snackText = '비중 합계는 100%로 설정하세요.'
      } else {
        this.setRule({'rules': this.rules});
      }
    },
  }

}
</script>
