<template>

<div class="filter-card">
    <v-textarea 
      v-model="description"
      outlined
      disabled
      auto-grow
      label="퀀트전략"
    >
    </v-textarea>

    <v-card-subtitle align="start">기본 필터</v-card-subtitle>    
    <v-card>
    <v-container>    
      <v-row justify="space-between">
        <v-col cols="12" md="4">
        시장: {{ market }}
        </v-col>
        <v-col cols="12" md="4">
        시총: {{ market_cap }} % 이하
        </v-col>
        <v-col cols="12" md="4">
        <v-item-group multiple>
         업종:                
        <v-item
          v-for="name in sector"
          :key="name"
        > 
          <v-chip> {{ name }} </v-chip>
        </v-item>
        </v-item-group>
        </v-col>
      </v-row>
    </v-container>
    </v-card>
</div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  name: 'FilterCardViewOnly',
  computed: {
    ...mapState(['debug', 'market', 'market_cap', 'sector', 'description'])
  },
  methods: {
  }
}
</script>
